<template>
  <div>
    <el-row type="flex" justify="space-between">
      <el-col :span="6" style="padding-left: 1%">
        <el-button type="primary"
                   @click="dialogState = true"
                   v-if="tenantCrop === '2a31c23a-c178-441614928053489'">
          添加日志
        </el-button>
      </el-col>
      <el-col :span="3">
          <div class="circle">
          </div>
          <span style="margin-left: 1%;font-size: 13px;color: #909399">
            电脑端
          </span>
          <div class="circle" style="background-color: #0bbd87;margin-left: 8%">
          </div>
          <span style="margin-left: 1%;font-size: 13px;color: #909399">
            手机端
          </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" :offset="6" class="block">
        <el-timeline>
          <el-timeline-item v-for="item in logArray"
                            :key="item.id"
                            :timestamp="item.createDate"
                            placement="top"
                            :color="item.type == 0 ? '#409eff': '#0bbd87'">
            <el-card>
              <h4 v-html="item.description" style="white-space: pre-wrap;"></h4>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-col>
    </el-row>
    <el-dialog
        v-if="dialogState"
        title="添加日志"
        :visible.sync="dialogState"
        width="40%"
        @close="close">
      <el-form label-width="80px" :model="form" :rules="rules">
        <el-row>
          <el-col :span="22" :offset="1">
            <el-form-item label="更新描述" prop="description">
              <el-input type="textarea" v-model="form.description" placeholder="请输入更新描述" :rows="4"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="1">
            <el-form-item label="所属产品" prop="type">
              <el-radio-group v-model="form.type">
                <el-radio :label="0">电脑端</el-radio>
                <el-radio :label="1">手机端</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="confirm" size="medium">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "upgrade-log",
  created() {
    this.queryUpgradeLog()
  },
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      dialogState: false,
      form: {
        description: "",
        type: "",
      },
      rules: {
        description: [{required: true, message: '请输入更新描述', trigger: 'blur'},],
        type: [{required: true, message: '请输入更新描述', trigger: 'change'},],
      },
      logArray: [],
      page: 1,
    }
  },
  methods: {
    close() {
      this.dialogState = false
      this.form.description = ""
      this.form.type = ""
    },
    confirm() {
      this.$axios({
        method: "post",
        url: "/upgradeLog/addUpgradeLog",
        data: {
          ...this.form
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "添加成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 700,
        })
        if (flag) {
          setTimeout(this.close, 500)
          this.logArray = []
          this.page = 1
          this.queryUpgradeLog()
        }
      })
    },
    queryUpgradeLog() {
      this.$axios({
        method: "get",
        url: "/upgradeLog/queryUpgradeLog",
        params: {
          page: this.page
        }
      }).then(response => {
        this.logArray.push(...response.data.data)
      })
    },
  }
}
</script>

<style scoped>
.block {
  max-height: 82vh;
  overflow: auto;
}
.block::-webkit-scrollbar {
  display: none;
}
.circle {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: #409eff;
  display: inline-block;
}
</style>